import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom"; // Install using npm install react-modal

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [endChat, setEndChat] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [userCategories, setUserCategories] = useState([]);
  const [isEndChatModalOpen, setIsEndChatModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditTemplateModal, setIsEditTemplateModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const [message, setMessage] = useState("");
  const [updatedName, setUpdatedName] = useState("");
  const [updatedPassword, setUpdatedPassword] = useState("");
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedCategoryIds, setUpdatedCategoryIds] = useState([]);

  const [updateEndChatValue, setUpdateEndChatValue] = useState(0);

  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newCategoryIds, setNewCategoryIds] = useState([]);

  // Fetch users and user categories on component mount
  useEffect(() => {
    fetchUsers();
    fetchUserCategories();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("/users"); // Replace with your API endpoint
      setUsers(response.data);
      setEndChat(response.endChat);
      setDefaultTemplate(response.defaultTemplate);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUserCategories = async () => {
    try {
      const response = await axios.get("/user-categories"); // Replace with your API endpoint
      setUserCategories(response.data);
    } catch (error) {
      console.error("Error fetching user categories:", error);
    }
  };

  const openEditModal = (user) => {
    setEditingUser(user._id);
    setUpdatedName(user.name);
    setUpdatedEmail(user.email);
    setUpdatedPassword("");
    setUpdatedCategoryIds(user.userCategoryIds || []);
    setIsEditModalOpen(true);
  };
  const openEndChatModal = () => {
    setUpdateEndChatValue(endChat?.value);
    setIsEndChatModalOpen(true);
  };
  const navigate = useNavigate();
  const routeToDep = () => {
    navigate("/admin/departments");
  };

  const openCreateModal = () => {
    setNewName("");
    setNewEmail("");
    setNewPassword("");
    setNewCategoryIds([]);
    setIsCreateModalOpen(true);
  };
  const editTemplateModal = () => {
    setMessage(defaultTemplate?.data)
    setIsEditTemplateModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditingUser(null);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };
  const closeEndChatModal = () => {
    setIsEndChatModalOpen(false);
  };
  const closeMessageModal = () => {
    setIsEditTemplateModalOpen(false);
  };

  const handleSave = async () => {
    try {
      await axios.put(`/update-user/${editingUser}`, {
        name: updatedName,
        email: updatedEmail,
        password: updatedPassword,
        userCategoryIds: updatedCategoryIds,
      }); // Replace with your API endpoint
      fetchUsers(); // Refresh the user list
      closeEditModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleEndChat = async () => {
    try {
      await axios.put(`/update-setting/${endChat._id}`, {
        value: updateEndChatValue,
      }); // Replace with your API endpoint
      fetchUsers(); // Refresh the user list
      closeEndChatModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleMessage = async () => {
    try {
      await axios.post(`/update-welcome-message/${defaultTemplate?._id}`, {
        data: message,
      }); // Replace with your API endpoint
      fetchUsers(); // Refresh the user list
      closeMessageModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleCreate = async () => {
    try {
      await axios.post("/register", {
        username: newName,
        email: newEmail,
        password: newPassword,
        userCategoryIds: newCategoryIds,
      }); // Replace with your API endpoint
      fetchUsers(); // Refresh the user list
      closeCreateModal();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };
  const handleCategoryChange = (e, setCategories) => {
    const { value, checked } = e.target;
    setCategories(prev =>
      checked ? [...prev, value] : prev.filter(id => id !== value)
    );
  };

  return (
    <div className="manage-users">
      <h1>Manage Users</h1>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <button onClick={openCreateModal} className="create-user-button">
          Create User
        </button>
        <button onClick={openEndChatModal} className="create-user-button">
          Chat Expiry Settings
        </button>
        <button onClick={routeToDep} className="create-user-button">
          Department Settings
        </button>
        <button onClick={editTemplateModal} className="create-user-button">
          Edit Menu Template
        </button>
      </div>
      <table className="user-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Department</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>
                {user.userCategories?.map(cat => cat.name).join(", ") || "---"}
              </td>
              <td>
                {user.email !== "admin@xepos.com" && (
                  <button onClick={() => openEditModal(user)}>Edit</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Editing User */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        className="edit-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Edit User</h2>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={updatedName}
              onChange={e => setUpdatedName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={updatedEmail}
              onChange={e => setUpdatedEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="text"
              value={updatedPassword}
              onChange={e => setUpdatedPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Departments:</label>
            {userCategories.map(category => (
              <div key={category._id}>
                <input
                  type="checkbox"
                  id={`category-${category._id}`}
                  value={category._id}
                  checked={updatedCategoryIds.includes(category._id)}
                  onChange={e => handleCategoryChange(e, setUpdatedCategoryIds)}
                />
                <label htmlFor={`category-${category._id}`}>
                  {category.name}
                </label>
              </div>
            ))}
          </div>
          <div className="modal-actions">
            <button
              type="button"
              className="modal-actions-save"
              onClick={handleSave}
            >
              Save
            </button>
            <button type="button" onClick={closeEditModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      {/* Modal for Editing EndChat */}
      <Modal
        isOpen={isEndChatModalOpen}
        onRequestClose={closeEndChatModal}
        className="edit-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Auto Chat Expiry Time</h2>
        <form>
          <div className="form-group">
            <label>Hours (Conversation don't have message):</label>
            <input
              type="number"
              value={updateEndChatValue}
              onChange={e => setUpdateEndChatValue(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <p>{endChat?.description}</p>
          </div>
          <div className="modal-actions">
            <button
              type="button"
              className="modal-actions-save"
              onClick={handleEndChat}
            >
              Save
            </button>
            <button type="button" onClick={closeEndChatModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal for Creating User */}
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={closeCreateModal}
        className="create-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Create User</h2>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={newName}
              onChange={e => setNewName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="text"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Departments:</label>
            {userCategories.map(category => (
              <div key={category._id}>
                <input
                  type="checkbox"
                  id={`new-category-${category._id}`}
                  value={category._id}
                  checked={newCategoryIds.includes(category._id)}
                  onChange={e => handleCategoryChange(e, setNewCategoryIds)}
                />
                <label htmlFor={`new-category-${category._id}`}>
                  {category.name}
                </label>
              </div>
            ))}
          </div>
          <div className="modal-actions">
            <button
              type="button"
              className="modal-actions-create"
              onClick={handleCreate}
            >
              Create
            </button>
            <button type="button" onClick={closeCreateModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      {/* Modal for Editing Message */}
      <Modal
        isOpen={isEditTemplateModal}
        onRequestClose={closeMessageModal}
        className="edit-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Default Menu Template</h2>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <p>{defaultTemplate?.name}</p>
          </div>
          <div className="form-group">
            <label>Message:</label>
            <textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              rows="10"
              cols="50"
            />
          </div>
          <div className="modal-actions">
            <button
              type="button"
              className="modal-actions-save"
              onClick={handleMessage}
            >
              Save
            </button>
            <button type="button" onClick={closeMessageModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ManageUsers;
