import React, { useEffect, useState } from "react";
import { Alert, Form } from "reactstrap";

// components
import StartButtons from "./StartButtons";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";

// interface
import { MessagesTypes } from "../../../../data/messages";

interface IndexProps {
  onSend: (data: any) => void;
  replyData: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
}
const Index = ({
  onSend,
  replyData,
  onSetReplyData,
  chatUserDetails,
}: IndexProps) => {
  /*
  more menu collapse
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
    setemojiArray(true);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);

  /*
  input text
  */
  const [text, setText] = useState<null | string>("");
  const onChangeText = (value: string) => {
    setText(value);
  };

  /*
  images
  */
  const [images, setImages] = useState<Array<any> | null | undefined>();
  const [imagePreviews, setImagePreviews] = useState<Array<{ src: string; file: File }>>([]);

  const onSelectImages = (selectedFiles: Array<File>) => {
    const previews = selectedFiles.map((file) => ({
      src: URL.createObjectURL(file),
      file: file,
    }));
    setImagePreviews([...imagePreviews, ...previews]);
    setImages([...selectedFiles]); // Keep images state unchanged
  };

  /*
  files
  */
  const [files, setFiles] = useState<Array<any> | null | undefined>();
  const onSelectFiles = (files: Array<any>) => {
    setFiles(files);
  };
  useEffect(() => {
    if (text || images || files) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files]);

  // emoji picker
  const [emojiArray, setemojiArray] = useState<any>("");
  const [emojiPicker, setemojiPicker] = useState<boolean>(false);
  const onEmojiClick = (event: any) => {
    setemojiArray([...emojiArray, event.emoji]);
    setText(text + event.emoji);
  };

// Remove selected image
  const removeImage = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent form submission when clicking remove button
    event.stopPropagation(); // Stop event bubbling

    // Revoke object URL to free memory
    URL.revokeObjectURL(imagePreviews[index].src);

    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(updatedPreviews);

    if (images) {
      const updatedImages = images.filter((_, i) => i !== index);
      setImages(updatedImages);
    }
  };


  // Submit Message
  const onSubmit = () => {
    let data: any = {};
    if (text) {
      data["text"] = text;
    }
    if (images && images.length) {
      data["image"] = images;
    }

    if (files && files.length) {
      data["attachments"] = files;
    }

    setText("");
    setImages(null);
    setFiles(null);
    setImagePreviews([]);
    setemojiPicker(false);
    onSend(data);
  };

  const onClearMedia = () => {
    setImages(null);
    setFiles(null);
    setImagePreviews([]); // Clear previews
  };

  // @ts-ignore
  return (
    <div className="chat-input-section p-3 p-lg-4">
      <Form
        id="chatinput-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="preview-container mb-2">
          {imagePreviews.length > 0 && (
            <div className="preview-scroll">
              {imagePreviews.map((image, index) => (
                <div key={index} className="preview-item">
                  <img src={image.src} alt="Preview" className="preview-img" />
                  <button
                    className="remove-btn"
                    onClick={(e) => removeImage(index, e)}
                  >
                    ❌
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="row g-0 align-items-center">
          <div className="col-auto">
            {
              <StartButtons
                onToggle={onToggle}
                onEmojiClick={onEmojiClick}
                setemojiPicker={setemojiPicker}
                emojiPicker={emojiPicker}
              />
            }
          </div>
          <div className="col">
            <InputSection value={text} onChange={onChangeText} />
          </div>
          <div className="col-auto">
            <EndButtons onSubmit={onSubmit} disabled={disabled} />
          </div>
        </div>
      </Form>
      {(images && images.length) || (files && files.length) ? (
        <Alert
          isOpen={true}
          toggle={onClearMedia}
          color="secondary"
          className="alert-dismiss-custom rounded-pill font-size-12 mb-1 selected-media"
          closeClassName="selected-media-close"
        >
          <div className="d-flex align-items-center">

            <p className="me-2 mb-0">
              {images && !files && ` You have selected ${images.length} images`}
              {files && !images && ` You have selected ${files.length} files`}
              {files &&
                images &&
                ` You have selected ${files.length} files & ${images.length} images.`}
            </p>
          </div>
        </Alert>
      ) : null}

      <MoreMenu
        isOpen={isOpen}
        onSelectImages={onSelectImages}
        onSelectFiles={onSelectFiles}
        onToggle={onToggle}
      />

      <Reply
        reply={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </div>

  );
};

export default Index;
