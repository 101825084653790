import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
interface AttachedFilesProps {
  chatUserDetails: any;
  onOpenVideo: () => void;
  onOpenAudio: () => void;
  onToggleFavourite: () => void;
  onToggleArchive: () => void;
}
const AttachedFiles = ({
  chatUserDetails,
  onOpenVideo,
  onOpenAudio,
  onToggleFavourite,
  onToggleArchive,
}: AttachedFilesProps) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departments, setDepartments] = useState<
    Array<{ _id: string; name: string }>
  >([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const onDeleteConversation = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to End Chat of this user?"
    );

    if (!userConfirmed) {
      return; // Exit if the user cancels
    }

    try {
      const response = await axios.post("/close-conversation", {
        conversationId: chatUserDetails.conversationId,
      });

    } catch (error) {
      console.error("An error occurred while closing the conversation:", error);
    }
  };
  const fetchDepartments = async () => {
    setIsLoadingDepartments(true);
    try {
      const response = await axios.get("/get-conversation-dep", {
        params: {
          cId: chatUserDetails.conversationId // Pass cId properly as a query parameter
        }
      });

      setDepartments(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      setIsLoadingDepartments(false);
    }
  };

  const onUpdateDepartment = async () => {
    if (!selectedDepartment) {
      alert("Please select a department.");
      return;
    }

    try {
      await axios.post("/change-conversation-dep", {
        conversationId: chatUserDetails.conversationId,
        userCategoryId: selectedDepartment,
      });
      alert("Department updated successfully.");
      toggleModal();
    } catch (error) {
      console.error("An error occurred while updating the department:", error);
    }
  };
  return (
    <div className="text-center border-bottom">
      <div className="row">
        <div className="col-sm col-4">
          <div className="mb-4">
            <Button color="none" type="button" className="btn avatar-sm p-0">
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bxs-message-alt-detail"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Message
            </h5>
          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">
            <Button
              color="none"
              className={classnames(
                "btn",
                "avatar-sm",
                "p-0",
                "favourite-btn",
                { active: chatUserDetails.isFavourite },
              )}
              onClick={onToggleFavourite}
            >
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bx-heart"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Favourite
            </h5>
          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">
            <Button
              color="none"
              className="btn avatar-sm p-0"
              onClick={onOpenAudio}
            >
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bxs-phone-call"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Audio
            </h5>
          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">
            <Button
              color="none"
              type="button"
              className="btn avatar-sm p-0"
              onClick={onOpenVideo}
            >
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bx-video"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Video
            </h5>
          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                color="none"
                className="btn avatar-sm p-0 dropdown-toggle"
                type="button"
              >
                <span className="avatar-title bg-light text-body rounded">
                  <i className="bx bx-dots-horizontal-rounded"></i>
                </span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  className=" d-flex justify-content-between align-items-center"
                  to="#"
                  onClick={onToggleArchive}
                >
                  {chatUserDetails.isArchived ? (
                    <>
                      Un-Archive{" "}
                      <i className="bx bx-archive-out text-muted"></i>
                    </>
                  ) : (
                    <>
                      Archive <i className="bx bx-archive text-muted"></i>
                    </>
                  )}
                </DropdownItem>
                <DropdownItem
                  className=" d-flex justify-content-between align-items-center"
                  to="#"
                >
                  Muted <i className="bx bx-microphone-off text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              More
            </h5>
          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">
            <Button
              color="none"
              type="button"
              className="btn avatar-sm p-0"
              onClick={onDeleteConversation}
            >
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bx-trash text-muted"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              End&nbsp;Chat
            </h5>
          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">
            <Button
              color="none"
              type="button"
              className="btn avatar-sm p-0"
              onClick={fetchDepartments}
            >
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bx-category"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Change Department
            </h5>
          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">

          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">

          </div>
        </div>
        <div className="col-sm col-4">
          <div className="mb-4">

          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Change Department</ModalHeader>
        <ModalBody>
          {isLoadingDepartments ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <div className="mb-3">
              <label htmlFor="department-select" className="form-label">
                Select Department
              </label>
              <select
                id="department-select"
                className="form-select"
                value={selectedDepartment}
                onChange={e => setSelectedDepartment(e.target.value)}
              >
                <option value="">-- Select a Department --</option>
                {departments.map(dept => (
                  <option key={dept._id} value={dept._id}>
                    {dept.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onUpdateDepartment}>
            Update
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AttachedFiles;
