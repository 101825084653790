import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal"; // Install using npm install react-modal

const Departments = () => {
  const [userCategories, setUserCategories] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const [updatedName, setUpdatedName] = useState("");
  const [updatedCode, setUpdatedCode] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");


  const [newName, setNewName] = useState("");
  const [newCode, setNewCode] = useState("");
  const [newDescription, setNewDescription] = useState("");

  // Fetch users and user categories on component mount
  useEffect(() => {
    fetchUserCategories();
  }, []);


  const fetchUserCategories = async () => {
    try {
      const response = await axios.get("/user-categories-list"); // Replace with your API endpoint
      setUserCategories(response.data);
    } catch (error) {
      console.error("Error fetching user categories:", error);
    }
  };

  const openEditModal = (user) => {
    setEditingUser(user._id);
    setUpdatedName(user.name);
    setUpdatedCode(user.code);
    setUpdatedDescription(user.description);
    setIsEditModalOpen(true);
  };
  const Delete =  async (user) => {
    try {
      await axios.post(`/delete-category/${user._id}`);
      await fetchUserCategories(); // Refresh the user list
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const openCreateModal = () => {
    setNewName("");
    setUpdatedCode("");
    setUpdatedDescription("");
    setIsCreateModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditingUser(null);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleSave = async () => {
    try {
      await axios.put(`/update-category/${editingUser}`, {
        name: updatedName,
        code: updatedCode,
        description: updatedDescription,
      }); // Replace with your API endpoint
      fetchUserCategories(); // Refresh the user list
      closeEditModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleCreate = async () => {
    try {
      await axios.post("/create-category", {
        name: newName,
        code: newCode,
        description: newDescription,
      }); // Replace with your API endpoint
      fetchUserCategories(); // Refresh the user list
      closeCreateModal();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  return (
    <div className="manage-users">
      <h1>Manage Departments</h1>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <button onClick={openCreateModal} className="create-user-button">
          Create Department
        </button>
      </div>
      <table className="user-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Code</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {userCategories.map(user => (
          <tr key={user._id}>
            <td>{user.name}</td>
            <td>{user.code}</td>
            <td>
              {user.description}
            </td>
            <td>
              <button onClick={() => openEditModal(user)}>Edit</button>
              <button style={{background: "#e52e2e", marginLeft:"5px"}} onClick={() => Delete(user)}>Delete</button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>

      {/* Modal for Editing User */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        className="edit-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Edit Department</h2>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={updatedName}
              onChange={e => setUpdatedName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Code:</label>
            <input
              type="number"
              value={updatedCode}
              onChange={e => setUpdatedCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <input
              type="text"
              value={updatedDescription}
              onChange={e => setUpdatedDescription(e.target.value)}
            />
          </div>
          <div className="modal-actions">
            <button
              type="button"
              className="modal-actions-save"
              onClick={handleSave}
            >
              Save
            </button>
            <button type="button" onClick={closeEditModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal for Creating User */}
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={closeCreateModal}
        className="create-modal"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <h2>Create User</h2>
        <form>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={newName}
              onChange={e => setNewName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Code:</label>
            <input
              type="number"
              value={newCode}
              onChange={e => setNewCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <input
              type="text"
              value={newDescription}
              onChange={e => setNewDescription(e.target.value)}
            />
          </div>
          <div className="modal-actions">
            <button
              type="button"
              className="modal-actions-create"
              onClick={handleCreate}
            >
              Create
            </button>
            <button type="button" onClick={closeCreateModal}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Departments;
