import React from "react";
import { Navigate } from "react-router-dom";

import Cookies from "js-cookie";

const AuthProtected = (props: any) => {
  const profileDetails = Cookies.get('authUser');
  /*
    redirect is un-auth access protected routes via url
  */
  if (!profileDetails) {
    return (
      <Navigate to={  "/auth-login"} />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
